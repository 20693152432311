<template>
    <slot>
        <div class="mb-1" v-if="isUpdate && formData.money_receipt_no">
            <p><b>Money Receipt No: </b>{{formData.money_receipt_no}}</p>
        </div>
        <div class="mb-1">
            <label class="form-label" for="paymentMode">Mode of Payment</label>
            <v-select
                placeholder="Select Cash & Bank Account"
                class="w-100"
                v-model="formData.mop_account_head_id"
                name="mop_account_head_id"
                :options="cashAndBank"
                label="name"
                :reduce="name => name.id"
            />
        </div>
        <div class="mb-1">
            <label class="form-label" for="mopReference">MoP Reference</label>
            <vField
                v-model="formData.receipt_reference"
                name="receipt_reference"
                type="text"
                class="form-control"
            />
        </div>
        <div class="mb-1">
            <label class="form-label" for="receiptAmount">Net Payable</label>
            <vField
                v-model="props.grandTotal"
                name="total"
                type="number"
                class="form-control text-right"
                disabled="disabled"
            />
        </div>
        <div class="mb-1">
            <label class="form-label" for="receiptAmount">Paid Amount</label>
            <vField
                v-model="formData.payment"
                name="amount"
                type="number"
                class="form-control text-right"
                @keyup="validatePaymentAmount"
            />
        </div>
    </slot>
</template>

<script setup>
import {computed, inject, onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import handleReceipt from "@/services/modules/receipt";

const $router = useRoute();
const {fetchCashAndBank} = handleReceipt();
const formData = inject('formData')
const showError = inject('showError')
const props = defineProps({
    grandTotal: {
        type: Number
    },
    isUpdate: {
        type: Boolean,
        default: false
    }
})

const companyId = $router.params.companyId;
const cashAndBank = ref([])

const setInitialValue = (array, field) => {
    if(!array.length) return;
    formData.value[field] = array[0].id
}

const totalAmount = computed(() => props.grandTotal)

const validatePaymentAmount = (event) => {
    const amount = event.target.value
    if (amount < 0) {
        showError("Payment Amount cannot be less than 0")
    }

    if (amount > totalAmount.value) {
        formData.value.payment = totalAmount.value
    }
}

watch(totalAmount, (newValue, oldValue) => {
    if (newValue && !props.isUpdate) {
        formData.value.payment = newValue
    }
})

onMounted(async () => {
    await Promise.all([
        fetchCashAndBank(companyId).then(res => {
            if (res.data) cashAndBank.value = res.data
        })
    ]).then(() => {
        if(! props.isUpdate) {
            setInitialValue(cashAndBank.value, 'mop_account_head_id');
        }
    })
    .catch(e => {})
})
</script>